import React, {useEffect, useState} from "react"
import { Spin, Empty, Row, Col, Select, Card, Form, Input, Upload, Button, Space, message } from "antd"
import { UploadOutlined } from '@ant-design/icons'
import { getBusinessInformation, updateBusinessInformation } from "../../api/profile.api"
import { getMainCategoryList } from "../../api/shared.api"
import { MAGENTO_META_URL } from '../../const/config'
import { handleError } from "../../utils/errorResponse"

import _ from "lodash"
import { uploadService } from "../../utils/uploader"

const { Option } = Select

export default function BusinessInfoForm(props) {
  const { vendor_id } = props
  const [currentData, setCurrentData] = useState({})
  const [mainCategory, setMainCategory] = useState([])
  const [loading, setLoading] = useState(false)
  const [submitLoading, setSubmitLoading] = useState(false)
  const [fileUpload, setFileUpload] = useState('')
  const [form] = Form.useForm()

  const formItemLayout = {
    labelCol: {
      xs: { span: 16 },
      sm: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 14 },
    },
  }

  const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
  }

  useEffect(() => {
    setLoading(true)
    const fetchData = async() => {
      try {
        const res = await getBusinessInformation({vendor_id})
        const res_data = res.data
        const res_category = await getMainCategoryList()
        setCurrentData(res_data)
        setMainCategory(res_category.data)
        setLoading(false)
      } catch (err) {
        console.error('unable to catch:', err)
        setLoading(false)
      }
    }
    fetchData()
  }, [vendor_id])

  const onFinish = async(values) => {
    try {
      setSubmitLoading(true)
      const formattedData = getFormattedPayload(values)
      await updateBusinessInformation(formattedData)
      setSubmitLoading(false)
      message.success('Update Succesfully...')
    } catch (err) {
      setSubmitLoading(false)
      message.warning(handleError(err))
    }
  }

  const getFormattedPayload = (values) => {
    values.vendor_id = vendor_id
    values.registration_docs_submission = fileUpload
    
    return values
  }

  const onReset = () => {
    form.resetFields()
  }

  const getInitialValues = () => {
    return {
      gst_number: getFormattedValue('gst_number').value,
      bank_information: getFormattedValue('bank_information').value,
      bank_name: getFormattedValue('bank_name').value,
      main_category: getFormattedValue('main_category').value,
      registration_docs_submission: getRegitrationDocsSubmissionImage()
    }
  }

  const getRegitrationDocsSubmissionImage = () => {
    const data = getFormattedValue('registration_docs_submission').value
    if(data) {
      const url = data.charAt(0) === '/' ? MAGENTO_META_URL+data : MAGENTO_META_URL+'/'+data
      return [ {
          uid: '-1',
          name: 'registration_docs_submission.png',
          status: 'done',
          url: url,
          thumbUrl: url
      }]
    }
    return []
  }

  const getFormattedValue = (code) => {
    return currentData.filter((item) => item.attribute_code === code)[0]
  }

  const getFile = (e) => {
    console.log('Upload event:', e)
    if (Array.isArray(e)) {
      return e
    }
   return e && e.fileList
  }

  const handleFileUpload = async (file) => {
    try {
      let progress = { percent: 1 }
      const formData = new FormData()
      formData.append("registration_docs_submission", file.file)
      formData.append("type", "registration_docs_submission")
      const res = await uploadService(formData)
      
      file.onProgress(progress)
      if(res.status === 200) {
        file.onProgress({ percent: 100})
        setFileUpload(res.data.url_path)
        file.onSuccess(res.data)
      } 

    } catch (err) {
      file.onError(err)
    }
  }

  const handleFileUploadChange = async (file) => {
    if(_.isEmpty(file.fileList)) {
      setFileUpload('')
    }
  }

  return (
    _.isEmpty(currentData) ? <Spin spinning={loading}><Empty /></Spin> :
    <Row>
      <Col span={4} />
      <Col span={24}>
        <Card bordered={false}>
          <Form form={form} onFinish={onFinish} initialValues={getInitialValues()} {...formItemLayout}>
            <Form.Item label="GST Number" name="gst_number">
              <Input />
            </Form.Item>
            <Form.Item label="Bank Name" name="bank_name" rules={[{ required: true, message: 'Please input Bank Name!'}]}>
              <Input />
            </Form.Item>
            <Form.Item label="Bank Account Number" name="bank_information" rules={[{ required: true, message: 'Please input Bank Account Number!'}]}>
              <Input />
            </Form.Item>
            <Form.Item label="Main Category" name="main_category" rules={[{ required: true, message: 'Please set your Main Category!'}]}>
              <Select>
                { mainCategory.map((item, index) => {
                  return <Option key={index} value={item.value}>{item.title}</Option>
                })}
              </Select>
            </Form.Item>
            <Form.Item label="Registration Documents Submission" name="registration_docs_submission" valuePropName="fileList" getValueFromEvent={getFile}>
              <Upload
                customRequest={handleFileUpload}
                onChange={handleFileUploadChange}
                listType="picture"
                maxCount={1}
              >
                <Button icon={<UploadOutlined />}>Upload</Button>
              </Upload>
            </Form.Item>
            <Form.Item {...tailLayout}>
              <Space size={100}>
                <Button loading={submitLoading} type="primary" htmlType="submit">Submit</Button>
                <Button htmlType="button" onClick={onReset}>Reset</Button>
              </Space>
            </Form.Item>
          </Form>
        </Card>
      </Col>
      <Col span={4} />
    </Row>
  )
}