import React from 'react'
import { Col, Row } from "antd"
import { ProCard } from '@ant-design/pro-components'

import VariationImage from './VariationImage'

export default function VariationImageWall({ formRef, specList, setSpecList, visible }) {
  const handleImageChange = (url, index, indexNum) => {
    console.log("url, index, indexNum ",url, index, indexNum)
    specList[indexNum].subSpecList[index].img = url
    setSpecList([...specList])
  }

  const deleteImage = (index,indexNum) => {
    specList[indexNum].subSpecList[index].img = null
    setSpecList([...specList])
  }

  return (
    <>
    { visible ?
      <ProCard
        bordered
        title="Variation Pictures"
      >
      <Row gutter={10} style={{maxWidth:'900px'}}>
        {
          specList && specList.map((item, index) => (   
            item.subSpecList && item.subSpecList.map((subListItem, subIndex) => (
              <Col key={subIndex} span={3}>
                {index >= 0 ?
                  <VariationImage 
                  formRef={formRef}
                  single={true}
                  subIndex={subIndex}
                  specList={specList}
                  defaultImg={subListItem.img || ''}
                  callBack={e => handleImageChange(e, subIndex, index)}
                  indexNum = {index}
                  showDelete={true}
                  deleteImage= {e => deleteImage(subIndex,index)}
                  /> 
                  : null }
              </Col>
            ))
          ))
        }
      </Row>
      </ProCard> 
        : null 
    }
    </>
  )
}