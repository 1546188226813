import React, {useEffect, useState} from "react"
import { Spin, Empty, Row, Col, Card, Form, Input, Button, Space, message } from "antd"
import { getShippingInformation, updateShippingInformation } from "../../api/profile.api"
import { handleError } from "../../utils/errorResponse"

import _ from "lodash"

export default function ShippingInfoForm(props) {
  const { vendor_id } = props
  const [currentData, setCurrentData] = useState({})
  const [loading, setLoading] = useState(false)
  const [submitLoading, setSubmitLoading] = useState(false)
  const [form] = Form.useForm()

  const formItemLayout = {
    labelCol: {
      xs: { span: 16 },
      sm: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 14 },
    },
  }

  const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
  }

  useEffect(() => {
    setLoading(true)
    const fetchData = async() => {
      try {
        const res = await getShippingInformation({vendor_id})
        const res_data = res.data
        setCurrentData(res_data)
        setLoading(false)
      } catch (err) {
        console.error('unable to catch:', err)
        setLoading(false)
      }
    }
    fetchData()
  }, [vendor_id])

  const onFinish = async(values) => {
    try {
      setSubmitLoading(true)
      const formattedData = getFormattedPayload(values)
      await updateShippingInformation(formattedData)
      setSubmitLoading(false)
      message.success('Update Succesfully...')
    } catch (err) {
      setSubmitLoading(false)
      message.warning(handleError(err))
    }
  }

  const getFormattedPayload = (values) => {
    values.vendor_id = vendor_id
    return values
  }

  const onReset = () => {
    form.resetFields()
  }

  const getInitialValues = () => {
    return {
      dhlpickupid: getFormattedValue('dhlpickupid').value,
    }
  }

  const getFormattedValue = (code) => {
    return currentData.filter((item) => item.attribute_code === code)[0]
  }

  return (
    _.isEmpty(currentData) ? <Spin spinning={loading}><Empty /></Spin> :
    <Row>
      <Col span={4} />
      <Col span={24}>
        <Card bordered={false}>
          <Form form={form} onFinish={onFinish} initialValues={getInitialValues()} {...formItemLayout}>
            <Form.Item label="DHL Pickup ID" name="dhlpickupid">
              <Input />
            </Form.Item>
            <Form.Item {...tailLayout}>
              <Space size={100}>
                <Button loading={submitLoading} type="primary" htmlType="submit">Submit</Button>
                <Button htmlType="button" onClick={onReset}>Reset</Button>
              </Space>
            </Form.Item>
          </Form>
        </Card>
      </Col>
      <Col span={4} />
    </Row>
  )
}