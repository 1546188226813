import React, {useEffect, useState} from "react"
import _ from "lodash"
import moment from "moment"
import { PageHeader, Spin, Empty, Tabs, Row, Col, Image, Descriptions } from "antd"
import { getProfileHeader } from "../../api/profile.api"
import { getUser } from "../../utils/auth"
import { MAGENTO_META_URL } from '../../const/config'
import { CalendarOutlined, GlobalOutlined, StockOutlined, PhoneOutlined } from '@ant-design/icons'
import SellerDetailForm from "../../modules/Profile/SellerDetailForm"
import BusinessInfoForm from "../../modules/Profile/BusinessInfoForm"
import ShippingInfoForm from "../../modules/Profile/ShippingInfoForm"

export default function Profile(props) {
  const LOGO_URL = MAGENTO_META_URL+'/ves_vendors/logo/'
  const [currentData, setCurrentData] = useState({})
  const [loading, setLoading] = useState(false)

  const getFieldValue = (key) => {
    return currentData.vendor.configs.filter((item) => item.path === key)[0].value
  }

  const getFormattedLocation = () => {
    return <>
      <GlobalOutlined /> {`${currentData.vendor.street}, ${currentData.vendor.country_id}`}
    </>
  }

  const renderContent = () => (
    <Row gutter={[8, 8]}>
      <Col span={6}><Image src={LOGO_URL+getFieldValue('general/store_information/logo')} width={200} /></Col>
      <Col span={16}>
        <Descriptions column={3} size="small" layout="vertical" bordered>
          <Descriptions.Item span={1} label="Name">{getFieldValue('general/store_information/name')}</Descriptions.Item>
          <Descriptions.Item span={1} label="Joined Since"><CalendarOutlined /> { moment(currentData.vendor.created_at).format('MMM DD, YYYY')}</Descriptions.Item>
          <Descriptions.Item span={1} label="Total Sales"><StockOutlined /> { currentData.total_sales }</Descriptions.Item>
          <Descriptions.Item span={2} label="Phone"><PhoneOutlined /> { currentData.vendor.telephone }</Descriptions.Item>
          <Descriptions.Item span={1} label="Location">{ getFormattedLocation() }</Descriptions.Item>
          <Descriptions.Item span={3} label="Description">{ getFieldValue('general/store_information/short_description') }</Descriptions.Item>
        </Descriptions>
      </Col>
    </Row>
  )

  const tab_items = [
    {
      label: 'Seller Details',
      key: 'seller_detail',
      children: <SellerDetailForm vendor_id={currentData.vendor.entity_id} {...props}/>
    },
    {
      label: 'Business Information',
      key: 'business_information',
      children: <BusinessInfoForm vendor_id={currentData.vendor.entity_id} {...props}/>
    },
    {
      label: 'Shipping Information',
      key: 'shipping_information',
      children: <ShippingInfoForm vendor_id={currentData.vendor.entity_id} {...props}/>
    }
  ]

  useEffect(() => {
    setLoading(true)
    const fetchData = async() => {
      try {
        const { vendor_id } = getUser()
        const res = await getProfileHeader({vendor_id})
        const res_data = res.data
        setCurrentData(res_data)
        setLoading(false)
      } catch (err) {
        console.error('unable to catch:', err)
        setLoading(false)
      }
    }
    fetchData()
  }, [])

  return (
    _.isEmpty(currentData) ? <Spin spinning={loading}><Empty /></Spin>  :
    <>
      <PageHeader 
        className="site-page-header"
        title="Account"
        onBack={() => window.history.back()}
        footer={
          <Tabs defaultActiveKey="seller_detail" type="card" items={tab_items} />
        }
      >
        {renderContent()}
      </PageHeader>
    </>
  )
}